export default [
  {
    key: "client_name",
    label: "Name",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "name_program",
    label: "Program",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "status_account",
    label: "Client Status",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "nameadvisor",
    label: "Advisor",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "create_name_user",
    label: "Request by",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "date_completed",
    label: "Completed by",
    thClass: "text-left",
    visible: true,
  },
  {
    key: "read",
    label: "Read",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    thClass: "text-center",
    tdClass: "p-1/2 ",
    visible: true,
  },
  {
    key: "payment",
    label: "Payment",
    thClass: "text-center",
    tdClass: "p-1/2 ",
    visible: true,
  },
];
