export default {
  filterPrincipal: {
    type: "input",
    inputType: "text",
    placeholder: "Client...",
    model: ""
  },
  filters: [
    {
      type: "datepicker",
      margin: true,
      showLabel: true,
/* 0 */   label: "From",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      },
      cols: 6
    },
    {
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "To",
/* 1 */   placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      },
      cols: 6
    },
    {
      type: "custom-select",
      label: "Client status",
      margin: true,
      showLabel: true,
      options: [],
/* 2 */   model: "",
      reduce: "value",
      selectText: "label",
      cols: 12,
      md: 2,
      visible: true,
    },
    {
      type: "select",
      label: "Programs",
      margin: true,
      showLabel: true,
      options: [
        { value: 0, label: 'All' },
        { value: 5, label: 'Debt Solution' },
        { value: 6, label: 'Credit Experts' },
        { value: 7, label: 'Boost Credit' },
      ],
/* 3 */ model: "",
      reduce: "value",
      selectText: "label",
      cols: 12,
      md: 2,
      visible: true,
    },
    {
      type: "select",
      label: "Advisor",
      margin: true,
      showLabel: true,
      options: [],
/* 4 */ model: "",
      reduce: "id",
      selectText: "user",
      cols: 12,
      md: 2,
      visible: true
    },
  ],
}
