<template>
  <b-modal
    size="lg"
    v-model="isVisible"
    modal-class="modal-primary"
    title="Tracking"
    title-tag="h3"
    @hidden="openMe(false)"
    :hide-footer="!(!notSpecialist || (notSpecialist && isCompleted))"
  >
    <account-client-header :account="client.account" :client="client.name" />
    <b-row>
      <b-col>
        <h5>Observation of advisor</h5>
        <b-form-textarea v-model="obs" rows="5" disabled />
      </b-col>
      <b-col>
        <h5>Files</h5>
        <b-table
          :items="myData.advisor"
          :fields="fieldsTable"
          class="mt-1"
          show-empty
          small
        >
          <template #cell(name_file)="data">
            <a :href="data.item.route" target="_blank">
              {{ data.item.name_file }}
            </a>
          </template>
          <template #cell(created_at)="data">
            <span v-if="data.value">
              {{ data.item.created_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <h5>Observation of specialist</h5>
        <b-form-textarea
          v-model="recomendation"
          rows="5"
          :disabled="(!notSpecialist && isCompleted) || notSpecialist"
        />
      </b-col>
      <b-col>
        <h5>File of credit report</h5>
        <b-form-file
          v-if="!isCompleted"
          v-model="file"
          class="w-75 ml-3 mt-3"
          :disabled="notSpecialist"
          @change="uploadFile($event)"
        />

        <b-table
          v-else
          :items="[myData.specialist]"
          :fields="fieldsTable"
          class="fs-6 mt-1"
          show-empty
          small
        >
          <template #cell(name_file)="data">
            <a :href="data.item.route" target="_blank">
              {{ data.item.name_file }}
            </a>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_at | myGlobalWithHour }}
            </span>
          </template>
        </b-table>

        <span>
          {{ file }}
        </span>
      </b-col>
    </b-row>
    <br />
    <template #modal-footer>
      <div class="w-100">
        <span
          class="fs-2 float-left mt-1"
          v-if="myData.general.name_user_recomendation"
        >
          Done by:
          <b-badge class="px-1" style="font-size: 1rem" variant="info">
            {{ myData.general.name_user_recomendation }} ||
            {{ myData.general.date_completed | myGlobalWithHour }}
          </b-badge>
        </span>
        <b-button
          v-if="!isCompleted"
          class="float-right"
          variant="success"
          size="lg"
          @click="sendData"
        >
          Completed
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
//components
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
//services
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
//data
import FilesFields from "@/views/commons/components/ncr-realtor/views/data/modal-tracking-fields.js";
//store
import { mapGetters } from "vuex";

export default {
  components: {
    AccountClientHeader,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      client: {
        account: "",
        name: "",
        realtor_id: "",
        account_id: "",
      },
      file: null,
      myData: {},
      fieldsTable: null,
      obs: "",
      recomendation: "",
    };
  },
  methods: {
    async sendData() {
      const parameters = {
        file_name: this.file ? this.file.name : "",
        file_size: this.file ? this.file.size : "",
        file_type: this.file ? this.file.type : "",
        fileapproved: this.file ? this.file.fileapproved : "",
        id_account: this.client.account_id,
        idmodul: this.moduleId,
        idrealtor: this.client.realtor_id,
        observation: this.recomendation,
        user_id: this.currentUser.user_id,
      };

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const data = await NcrRealtorService.addRecomendationNcrRealtor({
          ...parameters,
        });
        this.removePreloader();
        if (data) {
          this.showSuccessSwal();
          this.$emit("refreshTable");
          this.openMe(false);
        } else {
          this.showErrorSwal();
        }
      }
    },
    //esta funcion es para agreagr el "fileapproved"
    uploadFile(e) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = (e) => {
        this.file.fileapproved = e.target.result;
      };
    },
    async start() {
      this.fieldsTable = FilesFields;
      const data = await NcrRealtorService.getDataRealtor(
        this.clientData.realtor_id
      );

      this.myData = { ...data };

      this.obs = this.myData.general.obs;
      this.recomendation = this.myData.general.recomendation;
      this.client.name = this.clientData.name;
      this.client.account = this.clientData.account;
      this.client.realtor_id = this.clientData.realtor_id;
      this.client.account_id = this.clientData.account_id;
      this.client.read_status = this.clientData.read_status;

      if (this.client.read_status === 0) {
        this.$emit("changeStatusRead", this.clientData.realtor_id);
      }
    },
    openMe(open) {
      this.isVisible = open;
      if (!open) this.$emit("close");
    },
  },
  computed: {
    notSpecialist() {
      return this.moduleId != 11 ? true : false;
    },
    isCompleted() {
      return this.$route.meta.statusNCR ? true : false;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.start();
    this.removePreloader();
    this.openMe(true);
  },
};
</script>