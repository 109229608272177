<template>
  <b-container class="w-100" fluid>
    <filter-slot
      class="border-3 border-table-radius p-0 m-0"
      :class="
        currentUser.modul_id == 5 ? 'border-top-info' : 'border-top-primary'
      "
      :filter="Filters.filters"
      :filter-principal="Filters.filterPrincipal"
      :total-rows="pagination.totalRows"
      :paginate="pagination.paginate"
      :startPage="pagination.startPage"
      :to-page="pagination.toPage"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reload="$refs['ncrRealtorList'].refresh()"
      @reset-all-filters="refreshTable"
    >
      <template #custom-vselect>
        <FilterStatusAccount :filters="Filters.filters[2]" />
      </template>
      <template #table>
        <b-table
          class="m-0 p-0 w-100"
          ref="ncrRealtorList"
          :items="provider"
          :fields="changeFields"
          :current-page.sync="pagination.paginate.currentPage"
          :per-page.sync="pagination.paginate.perPage"
          :busy.sync="isBusy"
          show-empty
          sticky-header="500px"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <b-col>
              <b-row>
                <router-link
                  target="_blank"
                  v-if="moduleId != 22"
                  :to="{
                    name: nameRouteClientDashboard,
                    params: { idClient: `${data.item.id_account}` },
                  }"
                >
                  {{ data.item.client_name }}
                </router-link>
                <router-link
                  v-else-if="
                    moduleId == 22 &&
                    (currentUser.role_id === 1 ||
                      currentUser.role_id === 17 ||
                      currentUser.role_id === 2 ||
                      currentUser.role_id === 14)
                  "
                  :to="{
                    name: 'ce-customer-service-dashboard',
                    params: {
                      idClient: data.item.id_account,
                      hasAccess: true, // is alert
                      typeAccess: 6, // Welcome,
                      wayAccess: 4, // enter by modal
                    },
                  }"
                >
                  {{ data.item.client_name }}
                </router-link>
                <span v-else>{{ data.item.client_name }}</span>
              </b-row>
              <b-row>
                {{ data.item.account }}
              </b-row>
              <b-row>
                <badge-risky-clients
                  :id-account="data.item.id_account"
                  :risk-level="data.item.risk_level"
                  :risk-description="data.item.risk_description"
                  :risk-type="data.item.risk_type"
                />
              </b-row>
            </b-col>
          </template>
          <template #cell(status_account)="data">
            <div style="width: 155px">
              <status-account
                :account="data.item"
                :text="true"
              ></status-account>
            </div>
          </template>
          <template #cell(nameadvisor)="data">
            <center>
              {{ data.value }}
            </center>
          </template>
          <template #cell(create_name_user)="data">
            <b-col>
              <b-row>{{ data.item.create_name_user }}</b-row>
              <b-row>{{ data.item.created_at | myGlobalWithHour }}</b-row>
            </b-col>
          </template>
          <template #cell(date_completed)="data">
            <div>
              <span>{{ data.item.recomendation_name_user }}</span
              ><br />
              <span>{{ data.item.date_completed | myGlobalWithHour }}</span>
            </div>
          </template>
          <template #cell(read)="data">
            <center class="pt-1/2 pb-0">
              <b-badge
                pill
                :variant="data.item.read ? 'light-success' : 'light-danger'"
                class="w-75"
              >
                {{ data.item.read | statusNcrRealtorRead }}
              </b-badge>
            </center>
          </template>
          <template #cell(status)="data">
            <center>
              <b-badge
                pill
                :variant="data.item.status ? 'light-success' : 'light-warning'"
                :class="data.item.status ? 'text-success' : 'text-warning'"
                class="w-100"
              >
                {{ data.item.status | statusNcrRealtor }}
              </b-badge>
            </center>
          </template>
          <template #cell(tracking)="data">
            <div class="d-flex justify-content-center">
              <span class="clickable" @click="downloadBorrower(data.item)">
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  width="16"
                />
              </span>

              <feather-icon
                icon="FolderIcon"
                class="cursor-pointer mx-1"
                style="color: var(--warning)"
                size="20"
                @click="openModalTracking(data.item)"
              />

              <feather-icon
                v-if="$route.meta.statusNCR == 0 && $route.meta.module == 11"
                icon="XCircleIcon"
                class="cursor-pointer"
                style="color: var(--danger)"
                size="20"
                @click="deleteNcrRealtor(data.item.nc_id)"
              />
            </div>
          </template>
          <template #cell(payment)="data">
            <center>
              <feather-icon
                v-if="data.item.state_charge == 0"
                icon="DollarSignIcon"
                class="cursor-pointer"
                style="color: var(--warning)"
                size="20"
                @click="paymentCharge(data.item)"
              />
              <b-badge
                v-else-if="data.item.state_charge == 1"
                pill
                variant="light-success"
              >
              </b-badge>
            </center>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-tracking
      v-if="activeModalTracking"
      @close="closeModalTracking"
      @changeStatusRead="changeStatusRead($event)"
      @refreshTable="refreshTable"
      :clientData="client"
    />
    <payment-charge-modal
      v-if="paymentChargeOn"
      :data-charge="dataCharge"
      :title-add-charge="'ADD CHARGE'"
      @close="closePaymentChargeModal"
    />
  </b-container>
</template>
<script>
//services
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
//components
import ModalTracking from "@/views/commons/components/ncr-realtor/views/components/modals/ModalTracking.vue";
//store
import { mapGetters, mapState, mapActions } from "vuex";
//data
import Filters from "@/views/commons/components/ncr-realtor/views/data/ncr-realtor.filters";
import Fields from "@/views/commons/components/ncr-realtor/views/data/ncr-realtor.fields";
import { TruckDeliveryIcon } from "vue-tabler-icons";
import PaymentChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/PaymentChargeModal.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import ClientsConnectionService from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
export default {
  props: {
    /* statusNCR:{
            required:true,
            type:Number,
        } */
  },
  components: {
    StatusAccount,
    ModalTracking,
    PaymentChargeModal,
    BadgeRiskyClients,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      isBusy: false,
      fields: [],
      items: [],
      Filters: {},
      pagination: {
        startPageUrl: "",
        startPage: 1,
        endPage: 1,
        endPageUrl: "",
        nextPageUrlpage: "",
        nextPage: 2,
        path: "",
        prevPageUrl: "",
        toPage: 0,
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 25,
        },
      },
      advisorOptions: [],
      activeModalTracking: false,
      client: {},
      staticFields: null,
      paymentChargeOn: false,
      dataCharge: {},
    };
  },
  methods: {
    async provider(context) {
      const data = {
        advisor:
          this.currentUser.role_id === 3 ||
          this.currentUser.role_id === 15 ||
          this.currentUser.role_id === 16
            ? this.currentUser.user_id
            : this.Filters.filters[4].model,
        from: this.Filters.filters[0].model,
        module: this.moduleId,
        name_text: this.Filters.filterPrincipal.model,
        page: context.currentPage,
        perpage: this.pagination.paginate.perPage,
        order: "asc",
        orderby: 1,
        role_id: this.currentUser.role_id,
        selectmoduleid: this.Filters.filters[3].model || 0,
        status: null,
        statusclient: this.Filters.filters[2].model || "",
        statuss: this.statusNCR,
        to: this.Filters.filters[1].model,
        is_digital: this.$route.meta.is_digital
          ? this.$route.meta.is_digital
          : null,
      };
      const result = await NcrRealtorService.getNcrRealtor(data);
      this.items = result.data;

      (this.pagination.currentPage = result.current_page),
        (this.pagination.startPageUrl = result.first_page_url),
        (this.pagination.startPage = result.from),
        (this.pagination.endPage = result.last_page),
        (this.pagination.endPageUrl = result.last_page_url),
        (this.pagination.nextPageUrlpage = result.next_page_url),
        (this.pagination.nextPage = result.first_page_url),
        (this.pagination.path = result.path),
        (this.pagination.perPage = result.per_page),
        (this.pagination.prevPageUrl = result.prev_page_url),
        (this.pagination.toPage = result.to),
        (this.pagination.totalRows = result.total),
        (this.pagination.paginate = {
          currentPage: result.current_page,
          perPage: result.per_page,
        });

      return this.items || [];
    },
    async start() {
      this.Filters = Filters;
      this.fields = Fields;
      this.staticFields = Fields;

      await Promise.all([this.assignAdvisors(), this.getStatusAccountAction()]);
    },
    async assignAdvisors() {
      const parameters = {
        idmodule: this.moduleId,
        idrole: this.currentUser.role_id,
        iduser: this.currentUser.user_id,
      };
      if (this.moduleId === 7 && this.currentUser.role_id === 3) {
        this.Filters.filters[4].options = [
          {
            id: 0,
            user_name: "All",
          },
        ];
      } else {
        const { data } = await ClientsConnectionService.getUserModule();
        this.Filters.filters[4].options = data || [];
      }
    },
    onChangeCurrentPage(e) {
      this.pagination.paginate.currentPage = e;
    },
    async openModalTracking(row) {
      this.addPreloader();
      this.client.name = row.client_name;
      this.client.account = row.account;
      this.client.realtor_id = row.nc_id;
      this.client.account_id = row.id_account;
      this.client.read_status = row.read;

      this.activeModalTracking = true;
    },
    closeModalTracking() {
      this.activeModalTracking = false;
    },
    refreshTable() {
      this.$refs["ncrRealtorList"].refresh();
    },
    async changeStatusRead(id) {
      const parameters = {
        idrealtor: id,
        readd: 1,
      };
      if (this.moduleId != 11 && this.isCompleted) {
        const result = await NcrRealtorService.changeStatusRead({
          ...parameters,
        });

        result ? this.$refs["ncrRealtorList"].refresh() : this.showErrorSwal();
      }
    },
    paymentCharge(item) {
      this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: item.id_account,
      });
      const data = {
        id: item.charge_id,
        fee: "NCR REALTOR",
        amount: item.amount,
        t_charge: item.type_charge,
      };
      this.dataCharge = data;
      this.paymentChargeOn = true;
    },

    closePaymentChargeModal() {
      this.paymentChargeOn = false;
      this.$refs["ncrRealtorList"].refresh();
    },
    ...mapActions({
      getStatusAccountAction: "DebtSolutionZeroPayment/getStatusAccountAction",
    }),
    async deleteNcrRealtor(nc_id) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        const parameters = {
          id: nc_id,
          user_id: this.currentUser.user_id,
        };
        await NcrRealtorService.deleteNcrRealtor(parameters);
        this.refreshTable();
      }
    },
    async downloadBorrower(item) {
      this.addPreloader();
      try {
        const data = await NcrRealtorService.generateBorrowerSignaturePdf({
          account: item.id_account,
        });
        if (data.status === 200) {
          await this.forceFileDownloadPdf(data.data);
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async getAllStatusClientAccount() {
      this.Filters.filters[2].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.Filters.filters[2].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.Filters.filters[2].options.push(...data);
    },
  },
  computed: {
    isCompleted() {
      return this.$route.meta.statusNCR ? true : false;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    statusNCR() {
      return this.$route.meta.statusNCR;
    },
    changeFields() {
      this.fields[3].visible = this.$route.meta.is_digital !== 2;
      this.fields[9].visible = this.statusNCR == 1;
      this.fields[6].visible = this.statusNCR == 1;
      return this.fields.filter((item) => item.visible);
    },
    nameRouteClientDashboard() {
      switch (this.moduleId) {
        case 5:
          return "debt-solution-dashboard";
          break;
        case 6:
          return "boost-credit-dashboard";
          break;
        case 7:
          return "credit-experts-dashboard";
          break;
        case 11:
          return "specialists-client-dashboard";
        case 20:
          return "connection-clients-account";
          break;
        default:
          return null;
          break;
      }
    },
    ...mapState({
      per_page: (state) => state.appConfig.perPage,
      accountStatus: (state) => state.DebtSolutionZeroPayment.accountStatus,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
  },
  async created() {
    await this.start();
    await this.getAllStatusClientAccount();
  },
  mounted() {
    if (this.moduleId != 11) {
      this.Filters.filters[2].visible = true;
      this.Filters.filters[3].visible = false;
    } else {
      this.Filters.filters[2].visible = false;
      this.Filters.filters[3].visible = true;

      let paymentCol = this.fields.find((x) => x.key === "payment");
      paymentCol.thClass = "d-none";
      paymentCol.tdClass = "d-none";

      // this.fields[9].thClass = "d-none"
      // this.fields[9].tdClass = "d-none"
    }
    this.$emit("table", this.$refs.ncrRealtorList);
  },
};
</script>
